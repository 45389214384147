import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import Cropper from "cropperjs";

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
@ViewChild("image", { static: false })
public imageElement!: ElementRef;

@Input()
public imageSource!: any;
@Output()
outputEvent = new EventEmitter<string>();

public imageDestination: string;
private cropper!: Cropper;

constructor() { 
  this.imageDestination = "";
}

ngOnInit(): void {
}

ngAfterViewInit() {
  this.cropper = new Cropper(this.imageElement.nativeElement, {
    zoomable: false,
    scalable: false,
    aspectRatio: 1,
    background: false,
    crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/png");
        this.outputEvent.emit(this.imageDestination);
    }
});
}
}
