import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { Payment, CustomerType } from 'npx-family-happy-common';
import { PaymentService } from '../../services/payment-service/payment.service';
import { InvoiceDetailBody } from '../../shared/models/invoice-detail-body.model';
import { ServicesService } from 'src/app/services/services-service/services.service';
import { ServiceOverview } from 'src/app/shared/models/service-overview.modet';
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { NurseService } from 'src/app/services/nurse-service/nurse.service';
import { PetsitterService } from 'src/app/services/petsitter-service/petsitter.service';
import { CaregiverCreationBody } from 'src/app/shared/models/caregiver-creation-body.model';
import { ActiveCampaignService } from 'src/app/shared/services/active-campaign-service/active-campaign.service';
import { CaregiverPaymentCreationBody } from 'src/app/shared/models/caregiver-payment-creation-body.model';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    fiscalCode: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required, Validators.pattern('^.+,\\s+[\\dA-Z]+$')]),
    city: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    postalCode: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    privacy: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    phone: new FormControl('', [Validators.required]),
    provinceCode: new FormControl('', [Validators.required])
  });
  category: string = '-';
  paymentID: string = '-';
  emailPayer: string = '';
  seller: string = '';
  noPayerFound = false;
  isLoading = false;

  stripe: any;
  stripeElements: any;
  payment!: Payment;
  stripeFormLoaded = false;

  isCaregiverPayment = false;

  step = 1;
  expiredSession = false;
  submissionError = '';

  serviceOverview!: ServiceOverview;

  private emailCheckFailed = false; // To avoid people misusing the payment information

  private unsubscribe = new Subject<void>();
  environment: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private servicesService: ServicesService,
    private paymentService: PaymentService,
    private babysitterService: BabysitterService,
    private petsitterService: PetsitterService,
    private nurseService: NurseService,
    private activeCampaignService: ActiveCampaignService,
    private changeRef: ChangeDetectorRef
  ) {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      this.paymentID = params['payment'];
      this.emailPayer = params['email'];
      this.category = params['category'];
      this.seller = params['seller'];
      if (!this.paymentID && !this.emailPayer && !this.category) {
        this.expiredSession = true;
      }
    })
  }

  ngOnInit(): void {
    if (this.paymentID) {
      // Hide phone and provinceCode fields, available for caregivers only
      this.form.get('phone')?.setValue('');
      this.form.get('provinceCode')?.setValue('');
      this.form.get('phone')?.removeValidators(Validators.required);
      this.form.get('provinceCode')?.removeValidators(Validators.required);

      // Get payment info from backend
      this.paymentService.getPaymentByID(+this.paymentID)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((payment) => {
          // Check whether the email provided is the one linked to the payment object
          if (payment.emailPayer === this.emailPayer) {
            //this.handlePaymentData(payment);
            this.payment = payment;
            // Init form email so that customers cannot change it
            this.form.get('email')?.setValue(this.emailPayer);

            // Load Service details
            this.servicesService.getFHServiceOverview(payment.service.id, payment.provinceCode).subscribe((overview) => {
              this.serviceOverview = overview;

              overview.products.sort((prodA, prodB) => {
                const valueA = prodA.productType === 'SERVICE' ? 1 : 0;
                const valueB = prodB.productType === 'SERVICE' ? 1 : 0;
                if (valueA > valueB) {
                  return -1;
                } else if (valueA < valueB) {
                  return 1;
                } else {
                  return 0;
                }
              })
            })
            // Add listener for email changes
            /* this.form.get('email')?.valueChanges
              .pipe(debounceTime(250), takeUntil(this.unsubscribe))
              .subscribe((email) => {
                if (this.form.get('email')?.valid) {
                  // Change with getPossiblePayers from PaymentsAPI
                  this.isLoading = true;
                  this.paymentService.getPossiblePayers(email)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((payers) => {
                      if (payers.length > 0) {
                        const customerInfo = request.requestPersonalInfo;
                        this.initForm(customerInfo);
                        this.noPayerFound = false;
                      } else {
                        this.noPayerFound = true;
                      }
                      this.isLoading = false;
                    })
                }
              }) */
            // Set customer info
          } else {
            // If the email is not the same, show an error message and block everything else
            this.emailCheckFailed = true;
          }
        });
    } else {
      if (this.category) {
        this.isCaregiverPayment = true;
        // Load Service details
        this.servicesService.getFHServiceOverviewByBusiness(CustomerType.CAREGIVER, this.category).subscribe((overview) => {
          this.serviceOverview = overview;
        })
      }
    }

    if (!environment.production)
      this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => console.log(value, this.form));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('fhinput') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      // Required to avoid that users may use bad suggestions
      setTimeout(() => {
        // @ts-ignore Ignore lint error
        inputAddress.autocomplete = 'hoff';
      }, 2000);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('street');
        let formCity = this.form.get('city');
        let formCap = this.form.get('postalCode');
        let street = '';
        let streetNumber = '';

        place.address_components?.forEach((component: any) => {
          component.types.forEach((type: any) => {
            addressParts.push(type);
          });

          if (component.types.includes('route')) {
            street = component.long_name;
          }

          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          }

          if (component.types.includes('administrative_area_level_2')) {
            this.form.get('province')?.setValue(component.short_name);
          }
          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
          if (component.types.includes('country')) {
            this.form.get('country')?.setValue(component.long_name);
          }
        });
        const address = `${street}, ${streetNumber}`;

        formAddress?.setValue(address);
        this.changeRef.detectChanges();

        //formAddress?.setValidators([Validators.required, createAddressValidator(addressParts)]);
      })
    });
  }

  goToPayment() {
    if (this.form.valid) {
      this.isLoading = true;
      this.submissionError = '';
      // Update invoice details on payment
      const body: InvoiceDetailBody = {
        city: this.form.value.city,
        country: this.form.value.country,
        fiscalCode: this.form.value.fiscalCode,
        name: this.form.value.name,
        postalCode: this.form.value.postalCode,
        province: this.form.value.province,
        street: this.form.value.street,
        surname: this.form.value.surname,
        email: this.form.value.email
      }
      if (this.isCaregiverPayment) {
        const caregiverBody: CaregiverCreationBody = {
          ...body,
          phone: this.form.value.phone,
          provinceCode: this.form.value.provinceCode,
        }
        // Create Payment for Caregiver
        const caregiverPaymentBody: CaregiverPaymentCreationBody = {
          billingInfo: body,
          phone: caregiverBody.phone,
          provinceCode: caregiverBody.provinceCode,
          alias: this.seller,
          serviceID: this.serviceOverview.serviceID,
          products: this.serviceOverview.products
        }
        // Create caregiver payment
        this.paymentService.createCaregiverPayment(caregiverPaymentBody)
          .pipe(switchMap((res) => this.paymentService.getPaymentByID(res.paymentID)))
          .subscribe((payment) => {
            this.handlePaymentData(payment);
            // Create caregiver account
            switch (this.category) {
              case 'BABYSITTER':
                this.babysitterService.createBabysitterFromPayment(caregiverBody).subscribe((babysitter) => {
                  console.log('Babysitter profile created.');
                  // Update payment URLRelated
                  this.paymentService.updateURLRelated(this.payment.id, '/babysitter-detail/' + babysitter.docRef)
                    .subscribe((res) => console.log('URL Related updated.'));
                  // Add Babysitter to start_checkout_list (id: 89)
                  this.activeCampaignService.addCaregiverToACList(caregiverBody.email, 89).subscribe((res) => {
                    if (!environment.production)
                      console.log('Babysitter added to start_checkout list.');
                  })
                })
                break;
              case 'PETSITTER':
                this.petsitterService.createPetsitterFromPayment(caregiverBody).subscribe((petsitter) => {
                  console.log('Petsitter profile created.');
                  // Update payment URLRelated
                  this.paymentService.updateURLRelated(this.payment.id, '/petsitter-detail/' + petsitter.docRef)
                    .subscribe((res) => console.log('URL Related updated.'));
                  // Add Petsitter to start_checkout_list (id: 92)
                  this.activeCampaignService.addCaregiverToACList(caregiverBody.email, 89).subscribe((res) => {
                    if (!environment.production)
                      console.log('Babysitter added to start_checkout list.');
                  })
                })
                break;
              case 'NURSE':
                this.nurseService.createNurseFromPayment(caregiverBody).subscribe((nurse) => {
                  console.log('Nurse profile created.');
                  // Update payment URLRelated
                  this.paymentService.updateURLRelated(this.payment.id, '/nurse-detail/' + nurse.docRef)
                    .subscribe((res) => console.log('URL Related updated.'));
                  // Add Nurse to start_checkout_list (id: 94)
                  this.activeCampaignService.addCaregiverToACList(caregiverBody.email, 89).subscribe((res) => {
                    if (!environment.production)
                      console.log('Babysitter added to start_checkout list.');
                  })
                })
                break;
              default:
                break;
            }
          });
      } else {
        this.paymentService.updateInvoiceDetail(this.payment.id, body, this.form.value.phone, this.form.value.provinceCode).subscribe({
          next: (payment) => {
            this.handlePaymentData(payment);
          },
          error: () => {
            this.submissionError = `Si è verificato un errore nell'aggiornamento delle informazioni di fatturazione.<br>
          Si prega di ricontrollare i dati e riprovare.<br>
          Se l'errore persiste, puoi segnalarlo a <a href="mailto:servizioclienti@familyhappy.it">servizioclienti@familyhappy.it</a>.`;
            this.isLoading = false;
          }
        });
      }
    }

  }

  async performPayment() {
    // Reset the error message if present
    const messageContainer = document.querySelector('#payment-message');
    if (messageContainer)
      messageContainer.textContent = '';
    // Get the client secret
    let elements = this.stripeElements;
    this.isLoading = true;
    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${environment.bachecaURL}/payment-thank-you?payment=${this.payment.id}&email=${this.payment.emailPayer}`,
        receipt_email: '',
      },
    });
    this.isLoading = false;
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      if (messageContainer)
        messageContainer.textContent = error.message;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  getSubscriptionPeriod(period: string) {
    switch (period) {
      case 'SUBSCRIPTION':
        return 'ogni mese';
      case 'SUBSCRIPTION3':
        return 'ogni 3 mesi';
      case 'SUBSCRIPTION12':
        return 'ogni anno';
      case 'SUBSCRIPTION':
      default:
        return '';
    }
  }

  updateProvincia(provinceCode: string) {
    this.form.get('provinceCode')?.setValue(provinceCode);
  }

  private invokeStripe(clientSecret: string) {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        this.stripe = (<any>window).Stripe(environment.STRIPE_KEY);
        this.initStripeForm(clientSecret);
      };
      window.document.body.appendChild(script);
    }
  }

  private handlePaymentData(payment: Payment) {
    this.payment = payment;
    if (this.payment.status === 'Completed') {
      this.expiredSession = true;
    } else {
      // Load Stripe Js and create payment form
      this.invokeStripe(this.payment.clientSecret);

    }
  }

  get isFaultySession() {
    return this.emailCheckFailed || this.expiredSession;
  }

  private initStripeForm(clientSecret: string) {
    // Create Stripe Elements
    this.stripeElements = this.stripe.elements({ clientSecret, locale: 'it' });
    // Create Payment Element
    const paymentElement = this.stripeElements.create('payment', { layout: 'accordion' });
    // Mount it in the DOM
    paymentElement.mount('#payment-element');
    paymentElement.on('ready', () => {
      // Show payment button
      this.stripeFormLoaded = true;
      // Go to Payment
      this.step = 2;
      this.isLoading = false;
    })
  }

  private initForm(customerInfo: any) {
    this.form.setValue({
      ...this.form.value,
      fiscalCode: customerInfo.fiscalCode,
      name: customerInfo.name,
      surname: customerInfo.surname,
      street: customerInfo.street,
      city: customerInfo.city,
      province: customerInfo.province,
      postalCode: customerInfo.postalCode,
      country: 'Italia',
    }, { emitEvent: false })
    this.form.markAllAsTouched();
  }

}
