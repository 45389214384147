import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestUtilitiesService } from 'npx-family-happy-common';
import { RequestService } from 'src/app/services/request-service/request.service';

@Component({
  selector: 'app-pay-request',
  templateUrl: './pay-request.component.html',
  styleUrls: ['./pay-request.component.scss']
})
export class PayRequestComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private requestService: RequestService, private utilities: RequestUtilitiesService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const requestID = params.get('id');
      if (requestID) {
        this.requestService.getRequest(requestID).subscribe((requests) => {
          const request = requests[0];
          const category = request.requestPersonalInfo.requestCategory;
          const customer = request.requestPersonalInfo.customer;
          const service = request.requestPersonalInfo.service;
          const province = request.requestPersonalInfo.provincia;
          const hrefPattern = /href="([^"]+)"/;
          const paymentURL = this.utilities.getPaymentURL(category, customer, service, province).match(hrefPattern);
          if (paymentURL)
            location.href = paymentURL[1];
        })
      }
    })
  }

}
