import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PaymentService } from 'src/app/services/payment-service/payment.service';

@Component({
  selector: 'app-payment-thank-you',
  templateUrl: './payment-thank-you.component.html',
  styleUrls: ['./payment-thank-you.component.scss']
})
export class PaymentThankYouComponent implements OnInit, OnDestroy {
  title = 'Si è verificato un errore';
  errorMessage = '';
  isPaymentSuccessful = false;
  paymentID: string = '-';
  emailPayer: string = '';

  private unsubscribe = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private _location: Location, private paymentService: PaymentService) {
    this.activatedRoute.queryParamMap.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      const redirectStatus = params.get('redirect_status');
      this.paymentID = params.get('payment') ?? this.paymentID;
      this.emailPayer = params.get('email') ?? this.emailPayer;
      console.log('RedirectStatus:', redirectStatus);
      if (redirectStatus === 'succeeded') {
        this.isPaymentSuccessful = true;
        this.title = 'Congratulazioni!'
      } else if (redirectStatus === 'failed') {
        // Retrieve the error message.
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    // Get payment info from backend
    this.paymentService.getPaymentByID(+this.paymentID)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((payment) => {
        // Check whether the email provided is the one linked to the payment object
        if (payment.emailPayer === this.emailPayer && !this.isPaymentSuccessful) {
          this.errorMessage = payment.extra;
        }
      });
  }

  goBack() {
    this._location.back();
  }
}
