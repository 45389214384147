import { Component, Input, OnInit } from '@angular/core';
import { Ad } from 'npx-family-happy-common';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss']
})
export class RequestCardComponent implements OnInit {
  @Input() req!: Ad;

  constructor() { }

  ngOnInit(): void {
  }

}
