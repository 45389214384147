import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-example-modal',
  templateUrl: './example-modal.component.html',
  styleUrls: ['./example-modal.component.scss']
})
export class ExampleModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() exampleType!: string;

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.closeModal.emit();
  }

  dismiss(event: Event) {
    const elem = event.target as HTMLElement;
    if (elem === document.getElementById("modal-container")) {
      this.onClose();
    }
  }

}
