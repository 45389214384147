import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BabysitterFormComponent } from './pages/babysitter-form/babysitter-form.component';
import { ImageCropperComponent } from './shared/components/image-cropper/image-cropper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BabysitterService } from './services/babysitter-service/babysitter.service';
import { PsychoAttitudinalTestComponent } from './pages/psycho-attitudinal-test/psycho-attitudinal-test.component';
import { MatchingFormComponent } from './pages/matching-form/matching-form.component';
import { BulletinBoardComponent } from './pages/bulletin-board/bulletin-board.component';
import { RequestCardComponent } from './shared/components/request-card/request-card.component';
import { RequestDetailComponent } from './pages/request-detail/request-detail.component';
import { MyDatePipe } from './date.pipe';
import { ThankYouBachecaComponent } from './pages/thank-you-bacheca/thank-you-bacheca.component';
import { HeaderBarComponent } from './shared/components/header-bar/header-bar.component';
import { ExampleModalComponent } from './modals/example-modal/example-modal.component';
import { DisostruzioneTestComponent } from './pages/disostruzione-test/disostruzione-test.component';
import { NpxFamilyHappyCommonModule } from 'npx-family-happy-common';
import { CoreHttpInterceptor } from './core/interceptor/http-interceptor.service';
import { CallFtComponent } from './pages/call-ft/call-ft.component';
import { OpenChatComponent } from './pages/open-chat/open-chat.component';
import { PayRequestComponent } from './pages/pay-request/pay-request.component';
import { ConfirmQuotationComponent } from './pages/confirm-quotation/confirm-quotation.component';
import { PetsitterFormComponent } from './pages/petsitter-form/petsitter-form.component';
import { NurseFormComponent } from './pages/nurse-form/nurse-form.component';
import { NurseCertificationTestComponent } from './pages/nurse-certification-test/nurse-certification-test.component';
import { PetCertificationTestComponent } from './pages/pet-certification-test/pet-certification-test.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { PageMessageComponent } from './shared/components/page-message/page-message.component';
import { PaymentThankYouComponent } from './pages/payment-thank-you/payment-thank-you.component';
import { GtagModule } from 'angular-gtag';
import { ModalLoaderComponent } from './shared/components/modal-loader/modal-loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColfFormComponent } from './pages/colf-form/colf-form.component';
import { CookieBannerComponent } from './shared/components/cookie-banner/cookie-banner.component';

@NgModule({
  declarations: [
    AppComponent,
    BabysitterFormComponent,
    ImageCropperComponent,
    PsychoAttitudinalTestComponent,
    MatchingFormComponent,
    BulletinBoardComponent,
    RequestCardComponent,
    RequestDetailComponent,
    MyDatePipe,
    ThankYouBachecaComponent,
    HeaderBarComponent,
    ExampleModalComponent,
    DisostruzioneTestComponent,
    CallFtComponent,
    OpenChatComponent,
    PayRequestComponent,
    ConfirmQuotationComponent,
    PetsitterFormComponent,
    NurseFormComponent,
    NurseCertificationTestComponent,
    PetCertificationTestComponent,
    CheckoutPageComponent,
    PageMessageComponent,
    PaymentThankYouComponent,
    ModalLoaderComponent,
    ColfFormComponent,
    CookieBannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    NpxFamilyHappyCommonModule,
    NgbModule,
    GtagModule.forRoot({ trackingId: environment.gaTrackingID, trackPageviews: true })
  ],
  providers: [BabysitterService, { provide: HTTP_INTERCEPTORS, useClass: CoreHttpInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
