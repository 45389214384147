import { Component, OnInit } from '@angular/core';

export interface CookiePolicyState {
  accepted: boolean,
  expDate: Date | null
}

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
  hideBanner = false;

  constructor() { }

  ngOnInit(): void {
    const cookiePolicyState = JSON.parse(localStorage.getItem('fh-cookiepolicy') || '') as CookiePolicyState;
    if (cookiePolicyState &&
        (cookiePolicyState.accepted ||
        (cookiePolicyState.expDate && (new Date()).getTime() < new Date(cookiePolicyState.expDate ?? '').getTime()))) {
      this.hideBanner = true;
    }
  }

  closeBanner(accept: boolean) {
    this.hideBanner = true;
    let cookiePolicyState: CookiePolicyState;
    if (accept) {
      cookiePolicyState = {
        accepted: true,
        expDate: null
      };
    } else {
      // Save the cookie policy state for 1 hour if not accepted
      const now = new Date();
      now.setTime(now.getTime() + 3600000);
      cookiePolicyState = {
        accepted: false,
        expDate: now
      };
    }
    localStorage.setItem('fh-cookiepolicy', JSON.stringify(cookiePolicyState));
  }
}
