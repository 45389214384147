import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-call-ft',
  templateUrl: './call-ft.component.html',
  styleUrls: ['./call-ft.component.scss']
})
export class CallFtComponent implements OnInit {
  phone!: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const phoneNumber = params.get('phone');
      if (phoneNumber) {
        this.phone = phoneNumber;
        location.href = `tel:${phoneNumber}`;
      }
    })
  }

}
