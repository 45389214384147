import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  @ViewChild('dropdown')
  dropdown!: ElementRef;
  @ViewChild('dropdownMobile')
  dropdownMobile!: ElementRef;
  @ViewChild('parentServices')
  servicesDropdown!: ElementRef;
  @ViewChild('mobileMenu')
  mobileMenu!: ElementRef;
  windowWidth!: number;
  showMobileMenu = new FormControl(false);

  constructor() { }

  ngOnInit(): void {
    this.showMobileMenu.valueChanges.subscribe(value => {
      this.mobileMenu.nativeElement.style.visibility = 'visible';
    })
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      if (this.windowWidth > 1019) {
        this.showMobileMenu.setValue(false);
      }

      this.windowWidth = window.innerWidth;
    })
  }

  redirectAfterDropdown(event: Event) {
    event.preventDefault();
    const elemStyle = window.getComputedStyle(this.dropdown.nativeElement);
    if (elemStyle.visibility === 'visible') {
      window.location.href = "https://babysitter.familyhappy.it/welfare";
    }
  }

  redirectAfterDropdownMobile(event: Event) {
    event.preventDefault();
    const elemStyle = window.getComputedStyle(this.dropdownMobile.nativeElement);
    if (elemStyle.visibility === 'visible') {
      window.location.href = "https://babysitter.familyhappy.it/welfare";
    } else {
      this.dropdownMobile.nativeElement.style.visibility = 'visible';
      this.dropdownMobile.nativeElement.style.maxHeight = '40px';
      this.dropdownMobile.nativeElement.style.paddingTop = '10px';
    }
  }

  toggleServicesDropdown() {
    const elemStyle = window.getComputedStyle(this.servicesDropdown.nativeElement);
    if (elemStyle.visibility === 'hidden') {
      this.servicesDropdown.nativeElement.style.visibility = 'visible';
      this.servicesDropdown.nativeElement.style.maxHeight = '40px';
      this.servicesDropdown.nativeElement.style.paddingTop = '10px';
    } else {
      this.servicesDropdown.nativeElement.style.visibility = 'hidden';
      this.servicesDropdown.nativeElement.style.maxHeight = '0';
      this.servicesDropdown.nativeElement.style.paddingTop = '0';
    }
  }

}
