import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { CertificateService } from 'src/app/services/certificate-service/certificate.service';
import { FormService } from 'src/app/services/form-service/form.service';
import { MailService, Mail } from 'npx-family-happy-common';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-disostruzione-test',
  templateUrl: './disostruzione-test.component.html',
  styleUrls: ['./disostruzione-test.component.scss']
})
export class DisostruzioneTestComponent implements OnInit {
  form = new FormGroup({
    1: new FormControl('', Validators.required),
    2: new FormControl('', Validators.required),
    3: new FormControl('', Validators.required),
    4: new FormControl('', Validators.required),
    5: new FormControl('', Validators.required),
    6: new FormControl('', Validators.required),
    7: new FormControl('', Validators.required),
    8: new FormControl('', Validators.required),
    9: new FormControl('', Validators.required)
  });

  personalInfo = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  rightAnswers = ['B', 'B', 'B', 'C', 'A', 'C', 'B', 'A', 'C'];
  mistakes: number = 0;
  zapierData = {
    nome: '',
    cognome: '',
    email: '',
    esito: ''
  };
  modalLoader = false
  answers!: Array<string>
  esito = ""
  constructor(private babysitterService: BabysitterService, private mailService: MailService, private certificateService: CertificateService,
    private formService: FormService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(value => {
      if (this.form.valid) {
        this.mistakes = 0;
        // let answers = Object.values(value);
        this.answers = Object.values(value);

        this.answers.forEach((answer, i) => {
          if (answer !== this.rightAnswers[i]) {
            this.mistakes++
          }
        });
        if (this.mistakes > 2) {
          this.esito = "fallito"
        } else {
          this.esito = "superato"
        }
      }
    })

    this.personalInfo.valueChanges.subscribe(value => {
      if (this.personalInfo.valid) {
        this.zapierData = {
          nome: value.name,
          cognome: value.surname,
          email: value.email,
          esito: ''
        };
      }
      if ((value.email as string).match(/ $/) || (value.email as string).match(/^ /)) {
        this.personalInfo.get('email')?.setValue((value.email as string).trim());
      }
    })
  }

  submitForm() {
    this.modalLoader = true
    this.babysitterService.findBabysitterByEmail(this.personalInfo.get('email')?.value).subscribe(babysitter => {
      let answers = this.createBodyAnswer(this.answers)
      if (!babysitter.length) {
        alert('ERRORE: L\'indirizzo email inserito non è associato ad alcuna babysitter.');
        this.modalLoader = false
        this.modalService.dismissAll()
      } else {
        let senderEmail = 'servizioclienti@familyhappy.it';
        let senderEmailCert = 'servizioclienti@familyhappy.it';
        let babysitterEmail = environment.production ? this.personalInfo.get('email')?.value : 'm.cannata@familyhappy.it'; // Cambiamento temporaneo indirizzo email di test
        let babysitterName = this.personalInfo.get('name')?.value;
        let babysitterSurname = this.personalInfo.get('surname')?.value;
        let bodyCertMessage = `<p>Test disostruzione,<br>risposte del test:<br>${answers},Esito : ${this.esito}</p>`

        if (this.mistakes > 2) {  /* Babysitter did not pass the test. */
          this.esito = 'fallito'
          let mailCert = new Mail(senderEmailCert, 'servizioclienti@familyhappy.it', `CERTIFICAZIONI - BABYSITTER - ${babysitterEmail}`, bodyCertMessage, []);
          let courseLink = 'https://familyhappy.lt.acemlnb.com/Prod/link-tracker?notrack=1&notrack=1&redirectUrl=aHR0cHMlM0ElMkYlMkZ3d3cueW91dHViZS5jb20lMkZ3YXRjaCUzRnYlM0R1WW5fOXJmZlkxWSUyNnV0bV9zb3VyY2UlM0RBY3RpdmVDYW1wYWlnbiUyNnV0bV9tZWRpdW0lM0RlbWFpbCUyNnV0bV9jb250ZW50JTNERmFtaWx5JTI1MkJIYXBweSUyQi0lMkJpbml6aWElMkJsYSUyQmNlcnRpZmljYXppb25lJTI2dXRtX2NhbXBhaWduJTNEb25ib2FyZGluZ19jb3Jzb19iYWJ5c2l0dGVy&sig=H5nDbUBuf7DoAhxmR9CiyhMJtAm4qu1qeoHf8AhwswCi&iat=1660983825&a=%7C%7C650661975%7C%7C&account=familyhappy%2Eactivehosted%2Ecom&email=u1oXqn9zbfKm4mDMsA4h3gA3SuMkJhmkGexv49sZvNU%3D&s=14877772e33a8687c3907569b0f9aec1&i=41A86A23A126';

          let message = `<p>Car* ${babysitterName},<br> Purtroppo non hai superato il Test sul corso di Disostruzione Pediatrica.<br> Ma non temere: puoi seguire nuovamente il corso e rifare il test:
                         <a href="${courseLink}">clicca qui per il corso</a> - <a href="${environment.formBaseURL}/test-disostruzione">clicca qui per il test di certificazione</a>.</p>
                         <p>A presto e in bocca al lupo,<br> Il Team di Family+Happy</p>`

          let mail = new Mail(senderEmail, babysitterEmail, 'Esito test su Corso di Disostruzione Pediatrica', message, []);

          //EMAIL RISULTATI A servizioclienti
          this.mailService.sendCustom(environment.mailServiceURL, mailCert).subscribe(() => {
            return
          });
          this.mailService.sendCustom(environment.mailServiceURL, mail).subscribe(() => {
            /* if (environment.production) {
              this.zapierData.esito = 'Fallito';
              this.formService.sendDisostruzioneTestData(this.zapierData).subscribe(() => {
                window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
              });
            } else {
              window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
            } */
            window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
            this.modalLoader = false
            this.modalService.dismissAll()
          });

        } else {  /* Babysitter passed the test. */
          let mailCert = new Mail(senderEmailCert, 'servizioclienti@familyhappy.it', `CERTIFICAZIONI - BABYSITTER - ${babysitterEmail}`, bodyCertMessage, []);
          this.mailService.sendCustom(environment.mailServiceURL, mailCert).subscribe(() => {
            return
          });
          this.certificateService.createPDFCertificate(babysitter[0], senderEmail).then(() => {
            /* if (environment.production) {
              this.zapierData.esito = 'Superato';
              this.formService.sendDisostruzioneTestData(this.zapierData).subscribe(() => {
                window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
              });
            } */
            //window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
            this.modalLoader = false
            this.modalService.dismissAll()
          });
        }
      }
    })
  }

  createBodyAnswer(arr: Array<string>) {
    let htmlString = '';
    for (let i = 0; i < arr.length; i++) {
      htmlString += (i + 1) + '-' + arr[i] + '<br>';
    }
    return htmlString;
  }
}


