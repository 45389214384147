<div class="header"></div>

<div style="margin: 50px auto;" class="limit-content">
  <div *ngIf="formStep === 1" style="margin-bottom: 50px">
    <p style="font-size: 14pt">
      <b>Il seguente form è fondamentale per trovare lavoro, per compilarlo bastano solo 5 minuti.</b><br>
      Passo dopo passo ti aiuteremo a creare un <b>curriculum professionale</b>, in grado di suscitare l'interesse delle famiglie.
      Con la nostra esperienza abbiamo capito quanto sia fondamentale creare un curriculum ad hoc
      che faccia risaltare le caratteristiche fondamentali per le famiglie.
    </p>
    <div style="display: flex">
    <button type="button" class="button" (click)="openExampleCV()">Vedi un esempio del
      risultato</button>
      <div *ngIf="showSpinner" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <p class="back-btn" *ngIf="formStep !== 1" (click)="changeStep(false)">&larr; Indietro</p>
  <h2 *ngIf="formStep === 2">Esperienza & disponibilità</h2>
  <h2 *ngIf="formStep === 3">Certificazioni</h2>
  <h2 *ngIf="formStep === 4">Incarichi aggiuntivi</h2>

  <form [formGroup]="form">
    <div class="grid-container" formGroupName="step1" *ngIf="formStep === 1">
      <div>
        <label class="label" for="name">Nome<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.name')?.errors && form.get('step1.name')?.touched)}" type="text"
          id="name" formControlName="name" required>
        <p class="form-error" *ngIf="form.get('step1.name')?.errors?.['required'] && form.get('step1.name')?.touched">Il
          campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('step1.name')?.errors?.['spaces'] && form.get('step1.name')?.touched">Il
          nome non può iniziare o finire con uno spazio.</p>
      </div>
      <div>
        <label class="label" for="surname">Cognome<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.surname')?.errors && form.get('step1.surname')?.touched)}"
          type="text" id="surname" formControlName="surname" required>
        <p class="form-error"
          *ngIf="form.get('step1.surname')?.errors?.['required'] && form.get('step1.name')?.touched">Il campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('step1.surname')?.errors?.['spaces'] && form.get('step1.name')?.touched">
          Il cognome non può iniziare o finire con uno spazio.</p>
      </div>
      <div>
        <label class="label" for="email">Email<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.email')?.errors && form.get('step1.email')?.touched)}" type="text"
          id="email" formControlName="email" required>
        <p class="form-error" *ngIf="form.get('step1.email')?.getError('required') && form.get('step1.email')?.touched">
          Il campo è obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('step1.email')?.getError('email') && form.get('step1.email')?.touched">
          Inserisci un indirizzo email valido o controlla che non ci siano spazi all'inizio o alla fine.</p>
      </div>
      <div>
        <label class="label" for="phone">Cellulare<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.phone')?.errors && form.get('step1.phone')?.touched)}" type="text"
          id="phone" formControlName="phone" required>
        <p class="form-error" *ngIf="form.get('step1.phone')?.errors && form.get('step1.phone')?.touched">Il campo è
          obbligatorio.</p>
      </div>
      <div>
        <label class="label" for="address">Indirizzo<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.address')?.errors && form.get('step1.address')?.touched)}"
          type="text" id="address" formControlName="address" required>
        <p class="form-error"
          *ngIf="form.get('step1.address')?.errors && form.get('step1.address')?.hasError('required') && form.get('step1.address')?.touched">
          Il campo è obbligatorio.
        </p>
        <p class="form-error"
          *ngIf="form.get('step1.address')?.errors && form.get('step1.address')?.hasError('address') && form.get('step1.address')?.touched">
          Assicurati che l'indirizzo contenga tutti i seguenti elementi: Numero civico, CAP, Città.
        </p>
      </div>
      <div>
        <label class="label" for="provincia">Provincia dove vuoi lavorare<div class="required"><span>*</span></div>
        </label>
        <select
          [ngClass]="{'red-border': (form.get('step1.provincia')?.errors && form.get('step1.provincia')?.touched)}"
          class="form-select" id="provincia" formControlName="provincia" required>
          <option value="TO">Torino</option>
          <option value="MI">Milano</option>
          <option value="RM">Roma</option>
          <option value="BO">Bologna</option>
          <option value="PD">Padova</option>
          <option value="GE">Genova</option>
          <option value="FI">Firenze</option>
          <option value="BS">Brescia</option>
          <option value="BG">Bergamo</option>
          <option value="MB">Monza</option>
          <option value="VR">Altre province</option>
        </select>
        <p class="form-error" *ngIf="form.get('step1.provincia')?.errors && form.get('step1.provincia')?.touched">Il
          campo è obbligatorio.</p>
      </div>
      <div>
        <label class="label" for="birthDate">Data di nascita<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.birthDate')?.errors && form.get('step1.birthDate')?.touched)}"
          type="date" id="birthDate" formControlName="birthDate" required>
      </div>
    </div>

    <div class="grid-container" formGroupName="step2" *ngIf="formStep === 2">
      <div formGroupName="cluster1">
        <label class="label" for="years1"><b>Quanti anni di esperienza ha con anziani non autosufficienti?</b>
          <div class="required">
            <span>*</span>
          </div>
        </label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.cluster1.expYears')?.errors && form.get('step2.cluster1.expYears')?.touched)}"
          type="number" id="years1" formControlName="expYears" min="0" max="50" required>
        <p class="form-error"
          *ngIf="form.get('step2.cluster1.expYears')?.errors && form.get('step2.cluster1.expYears')?.touched">Il
          numero inserito non è valido.</p>

        <div *ngIf="form.get('step2.cluster1.expYears')?.value" class="form-field-group" style="gap: 20px;">
          <label class="label">Presso chi lavorava?
            <div class="required">
              <span>*</span>
            </div>
          </label>

          <div class="radio-list">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster1.employer')?.value === 'Casa di cura/RSA')}">
              <input style="display: none" type="radio" id="struttura1" formControlName="employer" name="employer"
                value="Casa di cura/RSA">
              <label for="struttura1">
                <div style="font-size: 0.8em;">A</div>Casa di cura/RSA
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster1.employer')?.value === 'Famiglia privata')}">
              <input style="display: none" type="radio" id="privato1" formControlName="employer" name="employer"
                value="Famiglia privata">
              <label for="privato1">
                <div style="font-size: 0.8em;">B</div>Famiglia privata
              </label>
            </div>
          </div>

          <div formGroupName="tasks" class="form-field-group">
            <span class="label">Quali di queste attività svolgeva?
              <div class="required">
                <span>*</span>
              </div>
            </span>

            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="assistance" formControlName="assistance" name="assistance">
                <label for="assistance">Assistenza e compagnia</label>
              </div>
              <div>
                <input type="checkbox" id="care" formControlName="care" name="care">
                <label for="care">Cura personale e igiene</label>
              </div>
              <div>
                <input type="checkbox" id="drugs" formControlName="drugs" name="drugs">
                <label for="drugs">Somministrazione di medicinali</label>
              </div>
              <div>
                <input type="checkbox" id="cooking" formControlName="cooking" name="cooking">
                <label for="cooking">Preparazione pasti</label>
              </div>
              <div *ngIf="form.get('step2.cluster1.employer')?.value === 'Famiglia privata'">
                <input type="checkbox" id="housing" formControlName="housing" name="housing">
                <label for="housing">Aiuto faccende domestiche</label>
              </div>
              <div>
                <input type="checkbox" id="helps-1" formControlName="other" name="helps-1">
                <label for="helps-1">Utilizzo degli ausili (specificare)</label>
              </div>
            </div>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster1.tasks.other')?.value">
            <span class="label">Specifica ausilio
              <div class="required">
                <span>*</span>
              </div>
            </span>
            <div>
              <input class="form-input" type="text" formControlName="help" maxlength="40"/>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster1.help')?.valid && form.get('step2.cluster1.help')?.touched">
              Il campo è obbligatorio</p>
          </div>
        </div>
      </div>

      <div formGroupName="cluster2">
        <label class="label" for="years2"><b>Quanti anni di esperienza ha con anziani autosufficienti?</b>
          <div class="required">
            <span>*</span>
          </div>
        </label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.cluster2.expYears')?.errors && form.get('step2.cluster2.expYears')?.touched)}"
          type="number" id="years2" formControlName="expYears" min="0" max="50" required>
        <p class="form-error"
          *ngIf="form.get('step2.cluster2.expYears')?.errors && form.get('step2.cluster2.expYears')?.touched">Il
          numero inserito non è valido.</p>

        <div *ngIf="form.get('step2.cluster2.expYears')?.value" class="form-field-group" style="gap: 20px;">
          <label class="label">Presso chi lavorava?
            <div class="required">
              <span>*</span>
            </div>
          </label>

          <div class="radio-list">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster2.employer')?.value === 'Casa di cura/RSA')}">
              <input style="display: none" type="radio" id="struttura2" formControlName="employer" name="employer"
                value="Casa di cura/RSA">
              <label for="struttura2">
                <div style="font-size: 0.8em;">B</div>Casa di cura/RSA
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster2.employer')?.value === 'Famiglia privata')}">
              <input style="display: none" type="radio" id="privato2" formControlName="employer" name="employer"
                value="Famiglia privata">
              <label for="privato2">
                <div style="font-size: 0.8em;">A</div>Famiglia privata
              </label>
            </div>
          </div>

          <div formGroupName="tasks" class="form-field-group">
            <span class="label">Quali di queste attività svolgeva?
              <div class="required">
                <span>*</span>
              </div>
            </span>

            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="assistance-2" formControlName="assistance" name="assistance-2">
                <label for="assistance-2">Assistenza e compagnia</label>
              </div>
              <div>
                <input type="checkbox" id="care-2" formControlName="care" name="care-2">
                <label for="care-2">Cura personale e igiene</label>
              </div>
              <div>
                <input type="checkbox" id="drugs-2" formControlName="drugs" name="drugs-2">
                <label for="drugs-2">Somministrazione di medicinali</label>
              </div>
              <div>
                <input type="checkbox" id="cooking-2" formControlName="cooking" name="cooking-2">
                <label for="cooking-2">Preparazione pasti</label>
              </div>
              <div *ngIf="form.get('step2.cluster2.employer')?.value === 'Famiglia privata'">
                <input type="checkbox" id="housing-2" formControlName="housing" name="housing-2">
                <label for="housing-2">Aiuto faccende domestiche</label>
              </div>
              <div>
                <input type="checkbox" id="helps-2" formControlName="other" name="helps-2">
                <label for="helps-2">Utilizzo degli ausili (specificare)</label>
              </div>
            </div>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster2.tasks.other')?.value">
            <span class="label">Specifica ausilio
              <div class="required">
                <span>*</span>
              </div>
            </span>
            <div>
              <input class="form-input" type="text" formControlName="help" maxlength="40"/>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster2.help')?.valid && form.get('step2.cluster2.help')?.touched">
              Il campo è obbligatorio</p>
          </div>
        </div>
      </div>

      <div *ngFor="let elem of availQuestions, index as i">
        <span class="label"><b>{{ elem.question }}</b>
          <div class="required">
            <span>*</span>
          </div>
        </span>
        <div class="radio-list">
          <div class="radio-btn" [ngClass]="{'selected': (form.get('step2')?.get(elem.form)?.value === true)}">
            <input style="display: none" type="radio" id="{{ elem.form }}-yes" [formControlName]="elem.form"
              name="{{ elem.form }}" [value]="true">
            <label for="{{ elem.form }}-yes">
              <div style="font-size: 0.8em;">A</div>Sì
            </label>
          </div>
          <div class="radio-btn" [ngClass]="{'selected': (form.get('step2')?.get(elem.form)?.value === false)}">
            <input style="display: none" type="radio" id="{{ elem.form }}-no" [formControlName]="elem.form"
              name="{{ elem.form }}" [value]="false">
            <label for="{{ elem.form }}-no">
              <div style="font-size: 0.8em;">B</div>No
            </label>
          </div>
        </div>
      </div>


      <div formGroupName="roles">
        <span class="label"><b>Per quali dei seguenti ruoli da disponibilità a lavorare? Scegli <u>più
              ruoli</u>:</b>
          <div class="required">
            <span>*</span>
          </div>
        </span>

        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="walking" formControlName="walking" name="walking">
            <label for="walking">Accompagnare la persona alle visite  a Piedi</label>
          </div>
          <div class="d-flex">
            <input type="checkbox" id="taxiNoCar" formControlName="taxiNoCar" name="taxiNoCar">
            <label for="taxiNoCar">Accompagnare la persona alle visite  con Auto Fornita dalla Famiglia</label>
          </div>
          <div>
            <input type="checkbox" id="taxi" formControlName="taxi" name="taxi">
            <label for="taxi">Accompagnare la persona alle visite  con Auto Propria</label>
          </div>
          <div>
            <input type="checkbox" id="cleaning" formControlName="cleaning" name="cleaning">
            <label for="cleaning">Provvedere all'igiene completo della persona</label>
          </div>
          <div>
            <input type="checkbox" id="dayShift" formControlName="dayShift" name="dayShift">
            <label for="dayShift">Badante diurna</label>
          </div>
          <div>
            <input type="checkbox" id="nightShift" formControlName="nightShift" name="nightShift">
            <label for="nightShift">Badante notturna</label>
          </div>
          <div>
            <input type="checkbox" id="changing" formControlName="changing" name="changing">
            <label for="changing">Cambio pannolone</label>
          </div>
          <div>
            <input type="checkbox" id="hospital" formControlName="hospital" name="hospital">
            <label for="hospital">Assistenza in ospedale</label>
          </div>
          <div>
            <input type="checkbox" id="coliving" formControlName="coliving" name="coliving">
            <label for="coliving">Disponibile alla convivenza</label>
          </div>
          <div>
            <input type="checkbox" id="relocation" formControlName="relocation" name="relocation">
            <label for="relocation">Disponibile alla trasferta</label>
          </div>
        </div>
        <p class="form-error" *ngIf="form.get('step2.roles')?.errors && form.get('step2.roles')?.touched">
          Seleziona almeno uno</p>
      </div>
    </div>

    <div class="grid-container" formGroupName="step3" *ngIf="formStep === 3">
      <div style="grid-column: 1/3">
        <div><span class="label"><b>La seguente domanda la aiuterà davvero molto a trovare
              velocemente lavoro:<br>
              Indichi il cognome della famiglia o delle famiglie che sarebbero disponibili a scrivere una referenza su
              di lei:</b>
          </span></div>

        <div class="form-input-container">
          <label class="label" for="parentSurname">COGNOME PRIMA FAMIGLIA</label>
          <input class="form-input" type="text" id="parentSurname" formControlName="parentSurname">
        </div>

        <div class="form-input-container">
          <label class="label" for="parentSurname2">COGNOME SECONDA FAMIGLIA</label>
          <input class="form-input" type="text" id="parentSurname2" formControlName="parentSurname2">
        </div>
      </div>
    </div>

    <div class="grid-container" formGroupName="step4" *ngIf="formStep === 4">
      <div formGroupName="certificates">
        <span class="label"><b>Quali certificazioni possiedi?</b></span>

        <div class="dynamic-field">
          <div class="form-field-group">
            <div *ngFor="let formControl of getCertificatesFormControls(), index as i">
              <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                type="text" [formControl]="$any(formControl)" placeholder="Certificato {{ i + 1 }}" />
            </div>
          </div>

          <div class="quantity-btn-container">
            <button class="form-input" (click)="changeCertificatesLength(true)">+</button>
            <button *ngIf="form.get('step4.certificates')?.value.length > 1" class="form-input"
              (click)="changeCertificatesLength(false)">-</button>
          </div>
        </div>
      </div>

      <div>
        <span class="label">Qual è il suo titolo di studio?<div class="required">
            <span>*</span>
          </div></span>
        <div class="radio-list">
          <div style="width: fit-content" class="radio-btn"
            [ngClass]="{'selected': (form.get('step4')?.get('title')?.value === 'Medie inferiori')}">
            <input style="display: none" type="radio" id="medie" formControlName="title" name="title"
              value="Medie inferiori">
            <label for="medie">
              <div style="font-size: 0.8em;">A</div>Medie inferiori
            </label>
          </div>
          <div style="width: fit-content" class="radio-btn"
            [ngClass]="{'selected': (form.get('step4')?.get('title')?.value === 'Medie superiori')}">
            <input style="display: none" type="radio" id="superiori" formControlName="title" name="title"
              value="Medie superiori">
            <label for="superiori">
              <div style="font-size: 0.8em;">B</div>Medie superiori
            </label>
          </div>
          <div class="form-graduation">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step4')?.get('title')?.value === 'Laurea')}">
              <input style="display: none" type="radio" id="laurea" formControlName="title" name="title" value="Laurea">
              <label for="laurea">
                <div style="font-size: 0.8em;">C</div>Laurea (specificare quale)
              </label>
            </div>
            <input *ngIf="form.get('step4.title')?.value === 'Laurea'" class="form-input" style="max-width: 400px"
              type="text" id="graduation-type" formControlName="graduationType" placeholder="Laurea"
              [ngClass]="{'red-border': (form.get('step4.graduationType')?.errors && form.get('step4.graduationType')?.touched)}" />
            <p class="form-error"
              *ngIf="form.get('step4.graduationType')?.errors && form.get('step4.graduationType')?.touched">Il campo è
              obbligatorio</p>
          </div>
        </div>
      </div>

      <div>
        <label class="label" for="english">Parli Inglese?<div class="required"><span>*</span></div>
        </label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.english')?.errors && form.get('step4.english')?.touched)}"
          id="english" formControlName="english" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.english')?.errors && form.get('step4.english')?.touched">Il campo
          è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="italian">Parli Italiano?<div class="required"><span>*</span></div>
        </label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.italian')?.errors && form.get('step4.italian')?.touched)}"
          id="italian" formControlName="italian" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.italian')?.errors && form.get('step4.italian')?.touched">Il campo
          è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="spanish">Parli Spagnolo?<div class="required"><span>*</span></div>
        </label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.spanish')?.errors && form.get('step4.spanish')?.touched)}"
          id="spanish" formControlName="spanish" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.spanish')?.errors && form.get('step4.spanish')?.touched">Il
          campo è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="spanish">Parli altre lingue?</label>
        <input class="form-input" type="text" id="otherlang1" formControlName="other1" placeholder="Altra lingua 1">
        <input class="form-input" type="text" id="otherlang2" formControlName="other2" placeholder="Altra lingua 2">
      </div>
    </div>

    <div class="grid-container" formGroupName="step5" *ngIf="formStep === 5">
      <div formGroupName="otherExp">
        <span class="label">Hai esperienza/disponibilità in una di queste attività aggiuntive?</span>
        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="glycemia" formControlName="glycemia" name="glycemia">
            <label for="glycemia">Controllo glicemia con apposito apparecchio</label>
          </div>
          <div>
            <input type="checkbox" id="injections" formControlName="injections" name="injections">
            <label for="injections">Iniezioni sicure</label>
          </div>
          <div>
            <input type="checkbox" id="dressings" formControlName="dressings" name="dressings">
            <label for="dressings">Medicazioni</label>
          </div>
          <div>
            <input type="checkbox" id="technology" formControlName="technology" name="technology">
            <label for="technology">Utilizzo di apparecchi tecnologici es. ricette elettroniche</label>
          </div>
        </div>
        <p class="form-error" *ngIf="form.get('step5.otherExp')?.errors && form.get('step5.otherExp')?.touched">Il
          campo è obbligatorio.</p>
      </div>

      <div formGroupName="daysAvail">
        <span class="label">In che giorni sei disponibile?<div class="required">
            <span>*</span>
          </div></span>
        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="monday" formControlName="mon" name="mon">
            <label for="monday">Lunedì</label>
          </div>
          <div>
            <input type="checkbox" id="tuesday" formControlName="tue" name="tue">
            <label for="tuesday">Martedì</label>
          </div>
          <div>
            <input type="checkbox" id="wednesday" formControlName="wed" name="wed">
            <label for="wednesday">Mercoledì</label>
          </div>
          <div>
            <input type="checkbox" id="thursday" formControlName="thu" name="thu">
            <label for="thursday">Giovedì</label>
          </div>
          <div>
            <input type="checkbox" id="friday" formControlName="fri" name="fri">
            <label for="friday">Venerdì</label>
          </div>
          <div>
            <input type="checkbox" id="saturday" formControlName="sat" name="sat">
            <label for="saturday">Sabato</label>
          </div>
          <div>
            <input type="checkbox" id="sunday" formControlName="sun" name="sun">
            <label for="sunday">Domenica</label>
          </div>
        </div>
        <p class="form-error" *ngIf="form.get('step5.daysAvail')?.errors && form.get('step5.daysAvail')?.touched">Il
          campo è obbligatorio.</p>
      </div>

      <div formGroupName="hoursAvail">
        <span class="label">In che orari sei disponibile?<div class="required">
            <span>*</span>
          </div></span>

        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="morning" formControlName="mor" name="mor">
            <label for="morning">Mattina</label>
          </div>
          <div>
            <input type="checkbox" id="afternoon" formControlName="aft" name="aft">
            <label for="afternoon">Pomeriggio</label>
          </div>
          <div>
            <input type="checkbox" id="evening" formControlName="eve" name="eve">
            <label for="evening">Sera</label>
          </div>
        </div>
        <p class="form-error" style="margin-top: 10px"
          *ngIf="form.get('step5.hoursAvail')?.errors && form.get('step5.hoursAvail')?.touched">Il
          campo è obbligatorio.</p>
      </div>

      <div style="grid-column: 1/3;" class="input-field two-columns">
        <label class="label" for="description"><b>Descriviti pensando al perchè la famiglia dovrebbe sceglierti.<br>
            In questo spazio NON mettere le esperienze di lavoro</b>
          <div class="required"><span>*</span></div>
          <p style="font-size: 14px; font-weight: 300;">Elenca i tuoi punti di forza che desideri che la famiglia
            sappia di te.
          </p>
        </label>
        <textarea rows="6" id="description" minlength="170" maxlength="340" formControlName="description"
          required></textarea>
        <p class="textarea-tip" [ngClass]="form.get('step5')?.get('description')?.valid ? 'green-text' : 'red-text'">
          Il valore deve essere compreso fra 170 e 340 caratteri ({{ descriptionLength }})</p>
      </div>
      <div class="two-columns">
        <span class="label" style="font-size: 14pt;">Carica una foto simile a <b>fototessera, sorridente e con sfondo
            neutro.</b>
          <div class="required">
            <span>*</span>
          </div>
        </span>
        <div class="image-upload">
          <label class="button d-flex align-items-center gap-1" (click)="touchButton()">
            <input id="image-selection" type="file" (change)="openModal($event)" (cancel)="abortSelection($event)">
            <span class="spinner-border text-light" style="width: 16px;height: 16px;" *ngIf="showSpinnerPhoto"></span>
            Carica
          </label>
          <img *ngIf="form.get('step5')?.get('photo')?.valid" src="../../../assets/icons/success-icon.svg" />

          <button type="button" class="button d-inline" style="margin-left: 25px;" (click)="openExamplePhoto()">
            <span class="spinner-border text-light" style="width: 16px;height: 16px;" *ngIf="showSpinnerExemple"></span>
            Vedi
            esempio</button>
        </div>
      </div>
    </div>

    <div style="margin-top: 50px" *ngIf="formStep !== 5">
      <button type="submit" class="button" id="next-button" style="width: 175px" (click)="changeStep(true)"
        [disabled]="!form.get('step' + formStep.toString())?.valid">Successivo
        &rarr;</button>
      <p *ngIf="form.get('step2')?.errors?.['experience']" style="color: red; margin: 5px 0;">Gli anni di
        esperienza
        in
        una fascia di età non possono essere superiori agli anni di esperienza totali.</p>
      <p *ngIf="!form.get('step' + formStep.toString())?.valid && (form.get('step' + formStep.toString())?.touched || form.get('step' + formStep.toString())?.dirty)"
        style="color: red; margin: 5px 0;">Assicurati di aver compilato correttamente tutti i campi richiesti.
      </p>
      <p *ngIf="!form.get('step5')?.get('photo')?.value && form.get('step5')?.touched"
        style="color: red; margin: 5px 0;">
        Carica la foto prima di proseguire.</p>
    </div>

    <div class="submit-btn-container" *ngIf="formStep === 5">
      <div style="display: flex">
        <button type="submit" class="button" id="submit-button" [disabled]="!form.get('step5')?.valid"
          (click)="submitForm()">Invia</button>
        <div class="lds-ring" *ngIf="showSpinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <p *ngIf="!form.get('step5')?.valid" style="color: red; margin: 5px 0;">Assicurati di aver compilato correttamente
        tutti i campi richiesti.</p>
    </div>
  </form>
</div>

<div class="modal" id="modal-container" *ngIf="showModal">
  <div class="modal-content">
    <app-image-cropper [imageSource]="sanitize(chosenPhoto)" (outputEvent)="getCroppedPhoto($event)">
    </app-image-cropper>
    <div class="modal-button-container">
      <button style="width: 120px" class="button" (click)="cropperAbort()">Annulla</button>
      <button style="width: 120px" class="button" (click)="uploadImage(finalPhoto)">Conferma</button>
    </div>
  </div>
</div>

<app-example-modal *ngIf="showExample" [exampleType]="exampleType" (closeModal)="closeExampleModal()">
</app-example-modal>
<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
