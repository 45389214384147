import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { Colf } from 'npx-family-happy-common';
import { ColfService } from 'src/app/services/colf-service/colf-service.service';
import { GoogleMapsService } from 'src/app/services/maps-service/google-maps.service';
import { trimSpaces } from 'src/app/shared/utils/trim-spaces.util';
import { createAddressValidator } from 'src/app/shared/validators/address.directives';
import { createCheckboxesValidator } from 'src/app/shared/validators/checkboxes.directives';
import { createSpacesValidator } from 'src/app/shared/validators/spaces.directives';
import { environment } from 'src/environments/environment';
import { v4 as UUID } from 'uuid';

@Component({
  selector: 'app-colf-form',
  templateUrl: './colf-form.component.html',
  styleUrls: ['./colf-form.component.scss']
})
export class ColfFormComponent implements OnInit {
  showModal = false;
  showExample = false;
  showSpinner = false;
  showSpinnerPhoto = false;
  showSpinnerExemple = false;
  modalLoader = false;
  exampleType!: string;
  colfID!: string;
  colf!: Colf;
  finalPhoto!: string;
  hasPayed!: boolean;
  globalDocRef!: string
  private oldAddress!: string | undefined

  form = new FormGroup({
    step1: new FormGroup({
      name: new FormControl('', [Validators.required, createSpacesValidator()]),
      surname: new FormControl('', [Validators.required, createSpacesValidator()]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl(null, Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl(''),
      cap: new FormControl(null),
      birthDate: new FormControl(undefined, Validators.required),
      provincia: new FormControl('TO', Validators.required)
    }),
    step2: new FormGroup({
      cluster1: new FormGroup({
        expYears: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employerStructure: new FormControl(''),
        employer: new FormControl(''),
        // help: new FormControl('')
      }),
      license: new FormControl(false, Validators.required),
      car: new FormControl(false, Validators.required),
      roles: new FormGroup({
        cooking: new FormControl(false),
        gardening: new FormControl(false),
        groceries: new FormControl(false),
        helping: new FormControl(false),
        houseCleaning: new FormControl(false),
        ironing: new FormControl(false),
        sewing: new FormControl(false),
        terraceCleaning: new FormControl(false),
        washingMachines: new FormControl(false),
        windowCleaning: new FormControl(false),
        relocation: new FormControl(false),
        coliving: new FormControl(false)
      }, createCheckboxesValidator(1))
    }),
    step3: new FormGroup({
      parentSurname: new FormControl(''),
      parentSurname2: new FormControl('')
    }),
    step4: new FormGroup({
      certificates: new FormArray([
        new FormControl('')
      ]),
      title: new FormControl('', Validators.required),
      graduationType: new FormControl(''),
      english: new FormControl('', Validators.required),
      italian: new FormControl('', Validators.required),
      spanish: new FormControl('', Validators.required),
      other1: new FormControl(''),
      other2: new FormControl('')
    }),
    step5: new FormGroup({
      // otherExp: new FormGroup({
      //   injections: new FormControl(false),
      //   dressings: new FormControl(false),
      //   glycemia: new FormControl(false),
      //   technology: new FormControl(false)
      // }),
      daysAvail: new FormGroup({
        mon: new FormControl(false),
        tue: new FormControl(false),
        wed: new FormControl(false),
        thu: new FormControl(false),
        fri: new FormControl(false),
        sat: new FormControl(false),
        sun: new FormControl(false)
      }, createCheckboxesValidator(1)),
      hoursAvail: new FormGroup({
        mor: new FormControl(false),
        aft: new FormControl(false),
        eve: new FormControl(false)
      }, createCheckboxesValidator(1)),
      description: new FormControl('', [Validators.required, Validators.minLength(170), Validators.maxLength(340)]),
      photo: new FormControl('', Validators.required)
    })
  });
  formStep = 1;
  descriptionLength: number = 0;
  chosenPhoto!: string;
  lastUpdateTimestamp!: Date;
  isNew: boolean = true;

  availQuestions = [
    {
      question: "Lei ha una patente in corso di validità?",
      form: "license"
    },
    {
      question: "Lei è automunita?",
      form: "car"
    }
  ];

  constructor(private colfService: ColfService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private googleMapsService: GoogleMapsService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.form.get('step5')?.get('description')?.valueChanges.subscribe(value => {
      this.descriptionLength = value.length;
    });
    /* this.form.valueChanges.subscribe((value) => {
      console.log('Form changed:', value);
    }) */

    this.route.queryParams.subscribe((params) => {
      const docRef = params['id'];
      this.globalDocRef = params['id'];
      if (docRef) {
        this.colfService.getSingleColf(docRef).subscribe((colfs) => {
          let colf = colfs[0];
          this.colf = colf;
          this.oldAddress = colf.info.address
          this.initForm(colf);
          this.lastUpdateTimestamp = colf.updateTimestamp;
          this.isNew = false;
        })
      }
    });

    //Conditional form validators

    for (let i = 1; i < 2; i++) {
      this.form.get(`step2.cluster${i}.expYears`)?.valueChanges.subscribe(value => {
        if (value) {
          this.form.get(`step2.cluster${i}.tasks`)?.setValidators(createCheckboxesValidator(1))
        } else {
          this.form.get(`step2.cluster${i}.tasks`)?.clearValidators();
          this.form.get(`step2.cluster${i}.tasks`)?.reset();
        }
      })
    }

    this.form.get('step2.cluster1.tasks.other')?.valueChanges.subscribe(value => {
      // if (value) {
      //   this.form.get('step2.cluster1.help')?.setValidators([Validators.required, Validators.maxLength(40)]);
      // } else {
      //   this.form.get('step2.cluster1.help')?.clearValidators();
      //   this.form.get('step2.cluster1.help')?.reset();
      // }
    })

    // this.form.get('step2.cluster2.tasks.other')?.valueChanges.subscribe(value => {
    //   if (value) {
    //     this.form.get('step2.cluster2.help')?.setValidators([Validators.required, Validators.maxLength(40)]);
    //   } else {
    //     this.form.get('step2.cluster2.help')?.clearValidators();
    //     this.form.get('step2.cluster2.help')?.reset();
    //   }
    // })

    /* Disable Taxisitter with car when Car field is false */
    this.form.get('step2.roles.taxi')?.disable({ emitEvent: false });
    this.form.get('step2.roles.taxiNoCar')?.disable({ emitEvent: false });

    this.form.get('step2.car')?.valueChanges.subscribe(value => {
      let license = this.form.get('step2.license')?.value;
      console.log('License - car:', license, value);
      if (value === false) {
        this.form.get('step2.roles.taxi')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxi')?.setValue(false);
      } else {
        if (license === true) {
          this.form.get('step2.roles.taxi')?.enable({ emitEvent: false });
        }
      }
    });

    /* Disable Taxisitter fields (except walking) when License field is false */

    this.form.get('step2.license')?.valueChanges.subscribe(value => {
      let car = this.form.get('step2.car')?.value;
      console.log('License - car:', value, car);
      if (value === false) {
        this.form.get('step2.roles.taxiNoCar')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxiNoCar')?.setValue(false);
        this.form.get('step2.roles.taxi')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxi')?.setValue(false);
      } else {
        if (car === true) {
          this.form.get('step2.roles.taxi')?.enable({ emitEvent: false });
        }
        this.form.get('step2.roles.taxiNoCar')?.enable({ emitEvent: false });
      }
    })
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('step1.address');
        let formCity = this.form.get('step1.city');
        let formCap = this.form.get('step1.cap');

        place.address_components?.forEach(component => {
          component.types.forEach(type => {
            addressParts.push(type);
          });

          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
        });

        formAddress?.setValidators([Validators.required, createAddressValidator(addressParts)]);

        formAddress?.setValue(place.formatted_address);
      })
    });
  }

  changeStep(next: boolean) {
    if (next === true) {
      this.formStep++
      this.submitFormStep();
    } else {
      this.formStep--
    }
  }

  async submitFormStep() {
    this.colf = await this.processFormData();
    this.colfService.updateColfFromForm(this.colf).subscribe((res: any) => {
      this.globalDocRef = res.message.match(/[\w-]+$/)[0];
    });
  }

  touchButton() {
    this.form.get('step5')?.get('photo')?.markAsTouched({ onlySelf: true });
    this.showSpinnerPhoto = true;
  }

  openModal(event: any) {
    this.showSpinnerPhoto = true;
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  openExamplePhoto() {
    this.exampleType = 'ColfPhoto';
    this.showExample = true;
    this.showSpinnerExemple = true;
  }

  closeExampleModal() {
    this.showExample = false;
    this.showSpinner = false;
    this.showSpinnerExemple = false;
  }

  getAge(date: string) {
    let today = new Date();
    let birthday = new Date(date);
    let age = today.getFullYear() - birthday.getFullYear();
    let month = today.getMonth() - birthday.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  initForm(colf: Colf) {
    if (!colf.lang) { // Patch colf lang
      colf.lang = {
        title: '',
        graduationType: '',
        specialities: {},
        english: '',
        french: '',
        italian: '',
        german: '',
        spanish: '',
        other1: '',
        other2: '',
      }
    }
    if (!colf.reference) { // Patch colf reference
      colf.reference = {
        f_surname: '',
        f_second_surname: ''
      }
    }
    this.form.setValue({
      step1: {
        name: trimSpaces(colf.info.name),
        surname: trimSpaces(colf.info.surname),
        email: colf.info.email,
        phone: colf.info.phone,
        address: colf.info.address,
        city: colf.info.city,
        cap: colf.info.cap,
        birthDate: colf.info.birthDate,
        provincia: colf.info.provincia
      },
      step2: {
        cluster1: {
          expYears: colf.exp.total,
          employer: colf.exp.employer,
          employerStructure: colf.exp.employer,
        },
        roles: {
          cooking: colf.avail.cooking,
          gardening: colf.avail.gardening,
          groceries: colf.avail.groceries,
          helping: colf.avail.helping,
          houseCleaning: colf.avail.houseCleaning,
          ironing: colf.avail.ironing,
          sewing: colf.avail.sewing,
          terraceCleaning: colf.avail.terraceCleaning,
          washingMachines: colf.avail.washingMachines,
          windowCleaning: colf.avail.windowCleaning,
          relocation: colf.avail.relocation,
          coliving: colf.avail.coliving
        },
        license: colf.avail.license,
        car: colf.avail.car
      },
      step3: {
        parentSurname: colf.reference ? colf.reference.f_surname : '',
        parentSurname2: colf.reference ? colf.reference.f_second_surname : ''
      },
      step4: {
        certificates: [''],
        title: colf.lang.title ?? '',
        graduationType: colf.lang.graduationType,
        english: colf.lang.english,
        italian: colf.lang.italian,
        spanish: colf.lang.spanish,
        other1: colf.lang.other1,
        other2: colf.lang.other2
      },
      step5: {
        // otherExp: {
        //   dressings: nurse.lang.specialities.dressings,
        //   glycemia: nurse.lang.specialities.glycemia,
        //   injections: nurse.lang.specialities.injections,
        //   technology: nurse.lang.specialities.technology,
        // },
        daysAvail: this.colfService.fromStringToAvailObject(colf.avail.days, 'days'),
        hoursAvail: this.colfService.fromStringToAvailObject(colf.avail.hours, 'hours'),
        description: colf.info.description,
        photo: colf.info.photo
      }
    }, { emitEvent: false })
    this.setCertificatesLenght(colf.certificates.colfCertificateList);
  }

  async processFormData(updateTimestamp?: Date) {
    /* this.zapierData = {
      ...this.form.get('step1')?.value,
      ...this.form.get('step2')?.value,
      ...this.form.get('step2')?.get('roles')?.value,
      ...this.form.get('step3')?.value,
      ...this.form.get('step4')?.value,
      ...this.form.get('step4')?.value,
      ...this.form.get('step4')?.get('daysAvail')?.value,
      ...this.form.get('step4')?.get('hoursAvail')?.value,
      ...this.form.get('step4')?.get('taskType')?.value
    };

    delete this.zapierData.daysAvail;
    delete this.zapierData.hoursAvail;
    delete this.zapierData.taskType;
    delete this.zapierData.roles; */

    /* this.nurseService.checkBabysitterCertification(this.form.get('step1.email')?.value).subscribe((res: boolean) => this.hasPayed = res); */

    // let cluster1Tasks: string = '';
    // Object.entries(this.form.get("step2.cluster1.tasks")?.value).forEach(([key, value]) => {
    //   if (value) {
    //     cluster1Tasks += `${key} `;
    //   } else {
    //     return;
    //   }
    // });

    // let cluster2Tasks: string = '';
    // Object.entries(this.form.get("step2.cluster2.tasks")?.value).forEach(([key, value]) => {
    //   if (value) {
    //     cluster2Tasks += `${key} `;
    //   } else {
    //     return;
    //   }
    // });

    let clustersExp = [];
    clustersExp.push(this.form.get('step2.cluster1.expYears')?.value);
    // clustersExp.push(this.form.get('step2.cluster2.expYears')?.value);
    let age = this.getAge(this.form.get('step1.birthDate')!.value);
    let expSum = clustersExp[0] + clustersExp[1];
    let totalExp = Math.max(...clustersExp, Math.min(expSum, (age - 16)));

    let colf: Colf = {
      // docRef: this.nurse ? this.nurse.docRef : this.globalDocRef,
      docRef: this.globalDocRef,
      certificate: '',
      info: {
        name: this.form.get("step1")?.get("name")?.value,
        surname: this.form.get("step1")?.get("surname")?.value,
        email: this.form.get("step1")?.get("email")?.value,
        phone: this.form.get("step1")?.get("phone")?.value,
        address: this.form.get("step1.address")?.value,
        city: this.form.get("step1.city")?.value,
        zone: '',
        cap: this.form.get("step1.cap")?.value,
        age,
        birthDate: this.form.get("step1")?.get("birthDate")?.value,
        provincia: this.form.get("step1")?.get("provincia")?.value,
        fiscalcode: '',
        resume: '',
        resumeHidden: '',
        video: '',
        notes: '',
        iban: '',
        description: this.form.get("step5")?.get("description")?.value,
        photo: this.form.get("step5")?.get("photo")?.value,
        subscription: this.hasPayed ? 'ABBONATO' : '',
        manualSubscription: this.colf ? this.colf.info.manualSubscription : '',
        expDate: this.colf ? this.colf.info.expDate : null,
        expDateManual: this.colf ? this.colf.info.expDateManual : null,
        stopWhatsapp: this.colf ? this.colf.info.stopWhatsapp : false,
        latitude: this.colf ? this.colf.info.latitude : 0,
        longitude: this.colf ? this.colf.info.longitude : 0,
      },
      exp: {
        total: this.form.get("step2.cluster1.expYears")?.value,
        employer: this.form.get("step2.cluster1.employer")?.value === 'Famiglia privata' ? this.form.get("step2.cluster1.employer")?.value : this.form.get("step2.cluster1.employerStructure")?.value
      },
      reference: {
        f_surname: this.form.get("step3")?.get("parentSurname")?.value ?? '',
        f_second_surname: this.form.get("step3.parentSurname2")?.value ?? ''
      },
      lang: {
        title: this.form.get("step4")?.get("title")?.value,
        graduationType: this.form.get("step4.graduationType")?.value,
        specialities: {
          injections: this.form.get("step5.otherExp")?.value.injections,
          dressings: this.form.get("step5.otherExp")?.value.dressings,
          glycemia: this.form.get("step5.otherExp")?.value.glycemia,
          technology: this.form.get("step5.otherExp")?.value.technology,
        },
        english: this.form.get("step4")?.get("english")?.value,
        italian: this.form.get("step4")?.get("italian")?.value,
        french: this.colf ? this.colf.lang.french : 'Non lo parlo',
        german: this.colf ? this.colf.lang.german : 'Non lo parlo',
        spanish: this.form.get("step4.spanish")?.value,
        other1: this.form.get("step4.other1")?.value,
        other2: this.form.get("step4.other2")?.value
      },
      avail: {
        cooking: this.form.get("step2.roles.cooking")?.value,
        gardening: this.form.get("step2.roles.gardening")?.value,
        groceries: this.form.get("step2.roles.groceries")?.value,
        helping: this.form.get("step2.roles.helping")?.value,
        houseCleaning: this.form.get("step2.roles.houseCleaning")?.value,
        ironing: this.form.get("step2.roles.ironing")?.value,
        sewing: this.form.get("step2.roles.sewing")?.value,
        terraceCleaning: this.form.get("step2.roles.terraceCleaning")?.value,
        washingMachines: this.form.get("step2.roles.washingMachines")?.value,
        windowCleaning: this.form.get("step2.roles.windowCleaning")?.value,
        license: this.form.get("step2.license")?.value,
        car: this.form.get("step2.car")?.value,
        smoker: this.colf ? this.colf.avail.smoker : false,
        days: this.colfService.fromAvailObjectToString(this.form.get("step5")?.get("daysAvail")?.value, 'days'),
        hours: this.colfService.fromAvailObjectToString(this.form.get("step5")?.get("hoursAvail")?.value, 'hours'),
        contract: this.colf ? this.colf.avail.contract : '',
        coliving: this.form.get("step2.roles.coliving")?.value,
        relocation: this.form.get("step2.roles.relocation")?.value
      },
      rating: {
        overall: this.colf ? this.colf.rating.overall : 0,
        punctuality: this.colf ? this.colf.rating.punctuality : 0,
        empathy: this.colf ? this.colf.rating.empathy : 0,
        behave: this.colf ? this.colf.rating.behave : 0,
        communication: this.colf ? this.colf.rating.communication : 0,
        dangerous: this.colf ? this.colf.rating.dangerous : false,
        attitude: this.colf ? this.colf.rating.attitude : '',
        notRated: this.colf ? this.colf.rating.notRated : false,
      },
      engagement: {
        selected: this.colf ? this.colf.engagement.selected : 0,
        chosen: this.colf ? this.colf.engagement.chosen : 0,
        discarded: this.colf ? this.colf.engagement.discarded : 0,
        ignored: this.colf ? this.colf.engagement.ignored : 0,
        substituted: this.colf ? this.colf.engagement.substituted : 0,
        applications: this.colf ? this.colf.engagement.applications : 0,
        chosenDate: this.colf ? this.colf.engagement.chosenDate : null,
        latestApplication: this.colf ? this.colf.engagement.latestApplication : null
      },
      certificates: {
        colfCertificateList: this.form.get('step4.certificates')?.value[0] ? this.form.get('step4.certificates')?.value : []
      },
      updateTimestamp: updateTimestamp ?? this.lastUpdateTimestamp
    };

    //calculate coordinates from address
    if (this.oldAddress !== colf.info.address) {
      let coordinates = await this.getCoordiantes(colf)
      colf = {
        ...colf,
        info: {
          ...colf.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }

    } else {
      colf = {
        ...colf,
        info: {
          ...colf.info,
          latitude: colf.info.latitude,
          longitude: colf.info.longitude
        }
      }

      this.colf = colf;
    }
    this.cdRef.detectChanges();
    return colf;
  }

  async submitForm() {
    this.modalLoader = true
    this.colf = await this.processFormData(new Date());
    this.colfService.createColf(this.colf).subscribe((res) => {
      console.log('Submit completion: ', res);
      /* if (environment.production) {
        console.log('Form data sent to Zapier');
      } */
      if (this.hasPayed || this.colf.info.manualSubscription === 'ABBONATO') {
        /* this.generateCV(); */
      } else {
        this.router.navigate(['thank-you/' + this.colf.info.email], { queryParams: { fromCV: true, name: this.colf.info.name, category: 'COLF' } });
        /* if(this.nurse.rating.attitude === '') {
          this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.nurseID } });
        } else {
          this.router.navigate(['thank-you-nurse/' + this.nurse.info.email], {queryParams: {fromCV: true, name: this.nurse.info.name}});
        } */
      }
      this.modalLoader = false
      this.modalService.dismissAll()
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
    this.showSpinnerPhoto = false;
  }
  abortSelection(event: any) {
    this.showSpinnerPhoto = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  uploadImage(image: string) {
    const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
    const path = 'pictures/' + UUID() + '.png';
    let storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed', () => { }, () => { }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
        console.log('File available at', downloadURL);
        this.form.get("step5")?.get("photo")?.setValue(downloadURL);
        alert('Foto caricata con successo!');
        this.showSpinnerPhoto = false;
      });
    })
    this.showModal = false;
  }

  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: FormArray = this.form.get('step4.certificates') as FormArray;

    if (more) {
      array.push(new FormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: FormArray = this.form.get('step4.certificates') as FormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new FormControl(data)));
    }
  }

  resetCertificates() {
    let array: FormArray = this.form.get('step4.certificates') as FormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: FormArray = this.form.get('step4.certificates') as FormArray;

    return array.controls;
  }

  openExampleCV() {
    this.showSpinner = true;
    this.exampleType = 'colfCV';
    this.showExample = true;
  }


  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Colf) {
    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }

}


