import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BabysitterFormComponent } from './pages/babysitter-form/babysitter-form.component';
import { BulletinBoardComponent } from './pages/bulletin-board/bulletin-board.component';
import { ConfirmQuotationComponent } from './pages/confirm-quotation/confirm-quotation.component';
import { DisostruzioneTestComponent } from './pages/disostruzione-test/disostruzione-test.component';
import { MatchingFormComponent } from './pages/matching-form/matching-form.component';
import { OpenChatComponent } from './pages/open-chat/open-chat.component';
import { PayRequestComponent } from './pages/pay-request/pay-request.component';
import { PsychoAttitudinalTestComponent } from './pages/psycho-attitudinal-test/psycho-attitudinal-test.component';
import { RequestDetailComponent } from './pages/request-detail/request-detail.component';
import { ThankYouBachecaComponent } from './pages/thank-you-bacheca/thank-you-bacheca.component';
import { PetsitterFormComponent } from './pages/petsitter-form/petsitter-form.component';
import { CallFtComponent } from './pages/call-ft/call-ft.component';
import { NurseFormComponent } from './pages/nurse-form/nurse-form.component';
import { NurseCertificationTestComponent } from './pages/nurse-certification-test/nurse-certification-test.component';
import { PetCertificationTestComponent } from './pages/pet-certification-test/pet-certification-test.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { PaymentThankYouComponent } from './pages/payment-thank-you/payment-thank-you.component';
import { ColfFormComponent } from './pages/colf-form/colf-form.component';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "bacheca"
  },
  {
    path: "babysitter-form",
    component: BabysitterFormComponent
  },
  {
    path: "petsitter-form",
    component: PetsitterFormComponent
  },
  {
    path: "nurse-form",
    component: NurseFormComponent
  },
  {
    path: "colf-form",
    component: ColfFormComponent
  },
  {
    path: "test-psico-attitudinale",
    component: PsychoAttitudinalTestComponent
  },
  {
    path: "matching-su-misura",
    component: MatchingFormComponent
  },
  {
    path: "bacheca",
    component: BulletinBoardComponent
  },
  {
    path: "richiesta/:id",
    component: RequestDetailComponent
  },
  {
    path: "thank-you/:email",
    component: ThankYouBachecaComponent
  },
  {
    path: "test-disostruzione",
    component: DisostruzioneTestComponent
  },
  {
    path: "nurse-certification-test",
    component: NurseCertificationTestComponent
  },
  {
    path: "pet-certification-test",
    component: PetCertificationTestComponent
  },
  {
    path: "call-ft/:phone",
    component: CallFtComponent
  },
  {
    path: "open-chat/:phone",
    component: OpenChatComponent
  },
  {
    path: "pay-request/:id",
    component: PayRequestComponent
  },
  {
    path: "confirm-quotation/:id",
    component: ConfirmQuotationComponent
  },
  {
    path: "checkout",
    component: CheckoutPageComponent
  },
  {
    path: 'payment-thank-you',
    component: PaymentThankYouComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
